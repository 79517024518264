import { HeadFC } from 'gatsby';
import * as React from 'react';

import calendar from '../../assets/calendar-icn.svg';
import { Footnote } from '../../components/Fonts/Footnote';
import { H4, H6 } from '../../components/Fonts/HTags';
import Footer from '../../components/Footer';
import {
  Copy,
  DateInfo,
  Divider,
  List,
  Space,
  SpaceSmall,
  StyledLink,
} from '../../components/LegalInfor';
import NavBar from '../../components/Nav';
import { Seo } from '../../components/Seo';
import { Spacer } from '../../components/Spacer';
import { environments } from '../../config';
import { fontWeightType } from '../../constants/enums';
import Base from '../../layout/Base';

const TermsAndConditions = () => {
  return (
    <Base>
      <NavBar>
        <H4 color="simpleWhite" fontWeight={fontWeightType.Bold}>
          TÉRMINOS Y CONDICIONES CARPEDIEM
        </H4>
        <Spacer size="22" />
        <DateInfo>
          <img width="18px" height="18px" src={calendar} alt="calendar" />
          <Footnote color="simpleWhite" fontWeight={fontWeightType.Medium}>
            Last updated: September 25, 2024
          </Footnote>
        </DateInfo>
        <Spacer size="28" />
      </NavBar>
      <Space />
      <Copy>
        <SpaceSmall />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          A través del sitio web{' '}
          <StyledLink href="www.carpediemediapp.com" target="_blank">
            carpediemediapp.com
          </StyledLink>{' '}
          (en adelante el Sitio), operado por SEBASTIÁN SÁNCHEZ, identificado con cédula de
          ciudadanía N°1.037.587.546 y JOSÉ CARLOS ÁLVAREZ, identificado con cédula de ciudadanía
          N°1.1128.421.202, médicos con 11 años de experiencia (en adelante CARPEDIEM), ponemos a
          disposición de médicos, estudiantes de medicina o personas interesadas en medicina,
          nuestra aplicación CARPEDIEM MEDIAPP (en adelante la App) con un banco de +3500 preguntas
          que abarcan varias especialidades de la medicina, con la finalidad de que puedan
          practicar, aprender y mejorar de forma remota para el examen de residencia en Colombia.
        </H6>
        <SpaceSmall />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          ACEPTACIÓN
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para nosotros es fundamental que tengas en cuenta que, al navegar por el Sitio,
          registrarte, suministrarnos datos, descargar la App y, en general, cualquier actuación
          tendiente a utilizar los servicios o acceder a los beneficios que te ofrecemos a través
          del Sitio y la App, estás aceptando estos Términos y Condiciones, por lo anterior, te
          pedimos que leas detenidamente los mismos.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Estos son los Términos y Condiciones que rigen el uso de los servicios que ofrecemos en el
          Sitio Web y la App y el acuerdo que opera entre cada usuario y CARPEDIEM. Estos Términos y
          Condiciones establecen los derechos y obligaciones de todos los usuarios en relación con
          el uso de los Servicio de CARPEDIEM.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Estos Términos y Condiciones se aplican a todos los visitantes, usuarios y otras personas
          que accedan al Sitio, a la App o utilicen el Servicio.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Su acceso y uso del Servicio también están condicionados a su aceptación y cumplimiento de
          la Política de Datos Personales, la cual se encuentra disponible en
          https://www.carpediemediapp.com/. Nuestra Política de Privacidad describe Nuestras
          políticas y procedimientos sobre la recopilación, uso y divulgación de Su información
          personal cuando Usted utiliza la Aplicación o el Sitio Web y le informa sobre Sus derechos
          de privacidad y cómo le protege la ley. Lea atentamente nuestra Política de Privacidad
          antes de utilizar nuestro Servicio.
        </Footnote>
        <SpaceSmall />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          MODIFICACIONES
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Nos reservamos el derecho a realizar modificaciones a los Términos y Condiciones, el
          Sitio, la App y a los contenidos, servicios y demás información incluida en los mismos; en
          caso de hacer modificaciones, informaremos a través del Sitio que hay una nueva versión
          del documento y ésta entrará en vigencia desde que se publique.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Si haces uso de los servicios o beneficios ofrecidos por nosotros con posterioridad a
          dichas modificaciones, entenderemos que has tenido conocimiento y aceptado las nuevas
          condiciones.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Si no está de acuerdo con las nuevas condiciones, en su totalidad o en parte, debes dejar
          de utilizar el sitio web, la App y el Servicio.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          TIPO DE USUARIOS
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Nuestros servicios van dirigidos a personas naturales, por lo anterior, todo usuario al
          acceder al Sitio, la App y/o registrarse declaras y garantizas que (i) aceptas estar
          sujeto a estos Términos y Condiciones, (ii) que tienes más de 18 años y estás facultado
          legalmente para contraer obligaciones en nombre propio o en nombre de la persona que
          representas.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          MEDIOS DE CONTACTO
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Toda comunicación entre nosotros, deberá constar por escrito, para lo cual, cuentas con el
          siguiente canal de comunicación para hacer consultas, solicitudes o acceder a nuestros
          servicios carpediemapp2023@gmail.com
        </Footnote>
        <SpaceSmall />
        <Divider />
        <SpaceSmall />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          REGISTRO DE CUENTA
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Al crear una cuenta con nosotros, debes proporcionarnos información precisa, completa y
          actualizada en todo momento. El incumplimiento de esta obligación constituye una
          infracción de las Condiciones, que puede dar lugar a la cancelación inmediata de tu cuenta
          en nuestro Servicio.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          No puedes utilizar como nombre de usuario el nombre de otra persona o entidad o que no
          esté legalmente disponible para su uso, un nombre o marca comercial que esté sujeto a
          cualquier derecho de otra persona o entidad que no seas tu sin la debida autorización, o
          un nombre que sea ofensivo, vulgar u obsceno.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para registrarte, deberás descargar la App en tu dispositivo móvil, suministrarnos algunos
          de los siguientes datos: nombre y apellido, correo electrónico, universidad en la que
          estudias, especialidad a la que aspiras, aceptar expresamente los Términos y Condiciones y
          la Política de Datos Personales, dar clic en “inscribirse” y te enviaremos un código al
          correo electrónico suministrado, con la finalidad de habilitar tu cuenta.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Una vez habilitada, iniciarás sesión con tu correo electrónico y contraseña y tendrás
          derecho a una prueba gratuita de los Servicios, una vez finalizada la prueba gratuita,
          deberás elegir el paquete que vas a adquirir, suministrar sus datos de pago y realizar el
          pago efectivo de los mismos, de lo contrario NO se hará efectivo el acceso al paquete
          seleccionado.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Ten en cuenta que la cuenta registrada es personal, por lo cual, no podrás cederla,
          compartirla o transferirla a terceros.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Cada usuario con cuenta registrada, será absolutamente responsable de mantener bajo
          reserva su código de acceso, de forma tal que le permita garantizar el control de acceso a
          la misma; lo anterior, ya que cada usuario, dueño de la cuenta registrada, será el único
          responsable de cada una de las interacciones, solicitudes, agendamientos, pagos y demás
          actividades realizadas a través de la cuenta.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En caso de que identifiques un acceso o uso no autorizado en tu cuenta, deberás
          reportarnos a través de carpediemapp2023@gmail.com, dentro de las dos (2) horas
          siguientes, contadas a partir del momento en que tuviste conocimiento del hecho.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Nos reservamos el derecho de rechazar cualquier solicitud de registro o de cancelar una
          cuenta previamente registrada, sin que ello genere algún derecho a indemnización o
          resarcimiento en favor del usuario.
        </Footnote>

        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          USO DE LA INFORMACIÓN SUMINISTRADA PARA EL REGISTRO
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para prestarte los servicios ofrecidos a través del Sitio y la App, podremos hacer uso de
          toda la información suministrada por ti, como usuario registrado, con la finalidad de
          contactarte, analizar y almacenar la información y cualquier actividad adicional que
          resulte necesaria para la adecuada ejecución de los servicios y beneficios ofrecidos en
          nuestro Sitio.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          SERVICIOS DE CARPEDIEM
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          A través de la cuenta de usuario registrada, el usuario podrá acceder a más de 3500
          preguntas disponibles que abarcan varias especialidades de la medicina, con la finalidad
          de que puedan practicar, aprender y mejorar de forma remota para el examen de residencia.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para ello, podrán acceder a test sin límite de veces en que lo realicen, dentro del
          alcance y cantidad de preguntas disponibles en la Suscripción adquirida, sin embargo, cada
          test tendrá un máximo de preguntas, el cual será informado oportunamente a través del
          Sitio.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          El Servicio o algunas partes del Servicio sólo están disponibles con una Suscripción de
          pago. Se te facturará por adelantado de forma recurrente y periódica (por ejemplo,
          mensual, trimestral, semestral o anualmente), dependiendo del tipo de plan de Suscripción
          que selecciones al adquirir la Suscripción. Al final de cada período, tu Suscripción se
          renovará automáticamente en las mismas condiciones, a menos que la canceles, modifiques o
          que CARPEDIEM la cancele.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Serás informado de los resultados de cada pregunta y podrás identificar tus áreas de
          fortaleza y áreas de oportunidad de mejora de acuerdo con ellos, monitorearlos y hacer
          seguimiento al proceso de mejora.
        </Footnote>

        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Sin embargo, como usuario, RECONOCES y ACEPTAS que los resultados de los test ofrecidos
          por CARPEDIEM a través del Sitio NO comportan ninguna garantía respecto a la posibilidad
          que tenga o no el usuario de obtener resultados favorables en el examen de residencia de
          la respectiva universidad, son medios para practicar, mejorar y adquirir nuevo
          conocimiento disponible, con base en la experiencia y el conocimiento de los médicos de
          CARPEDIEM y soportados en una herramienta tecnológica basada en la estadística.
        </Footnote>

        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          PRUEBA GRATUITA
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM puede, a su entera discreción, ofrecer una Suscripción con una Prueba Gratuita
          durante un periodo de tiempo limitado y un número limitado de preguntas que pueden hacerse
          durante la Prueba Gratuita. Una vez terminado el periodo de la Prueba Gratuita, el usuario
          será notificado e invitado a adquirir un plan para poder continuar realizando los test.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En cualquier momento y sin previo aviso, la Empresa se reserva el derecho a (i) modificar
          los términos y condiciones de la oferta de Prueba Gratuita, o (ii) cancelar dicha oferta
          de Prueba Gratuita.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          FACTURACIÓN Y FORMA DE PAGO
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          El usuario deberá proporcionar a CARPEDIEM información de facturación precisa y completa,
          incluyendo nombre completo, dirección, ciudad, código postal, número de teléfono y una
          información válida sobre el método de pago. En caso de que, por cualquier motivo, no se
          produzca la facturación automática, CARPEDIEM emitirá una factura electrónica en la que se
          le indicará que deberá proceder manualmente, dentro de un plazo determinado, al pago
          íntegro correspondiente al periodo de facturación indicado en la factura.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para realizar el pago, CARPEDIEM habilitó la pasarela de pagos electrónicos e-payco,a
          través de la cual solicitará la información necesaria al usuario para realizar el pago y
          solo se recibirán pagos con tarjeta de crédito.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          CAMBIOS EN LAS TARIFAS
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM, a su entera discreción y en cualquier momento, podrá modificar las cuotas de
          Suscripción. Cualquier cambio en las cuotas de Suscripción entrará en vigor al final del
          periodo de Suscripción vigente en ese momento. CARPEDIEM le avisará con una antelación
          razonable de cualquier cambio en las cuotas de Suscripción para darle la oportunidad de
          cancelar su Suscripción antes de que dicho cambio entre en vigor. Su uso continuado del
          Servicio después de que el cambio en la cuota de Suscripción entre en vigor constituye su
          aceptación de pagar el importe de la cuota de Suscripción modificada.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          PROMOCIONES
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM, a su entera discreción y en cualquier momento, podrá ofrecer descuentos en los
          valores de los planes, para lo cual, en cada caso, aplicarán las condiciones y
          restricciones anunciadas para cada promoción o descuento, estando CARPEDIEM en libertad de
          decidir si el mismo aplica para todos los planes, por vigencia, por cantidad de preguntas
          o cualquier otra condición o modalidad que considere, sin embargo, CARPEDIEM siempre
          anunciará de forma previa, clara y completa a los usuarios las condiciones y restricciones
          aplicables para que, con base en ellas, el usuario libremente decida si está interesado en
          adquirirlo o no.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          AUTORIZACIÓN PARA EL DÉBITO AUTOMÁTICO
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Teniendo en cuenta la naturaleza de la Suscripción, autorizas expresamente para que
          CARPEDIEM realice el débito automático con la periodicidad seleccionada y a la tarjeta de
          crédito indicada al momento de realizar la Suscripción.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          CANCELACIÓN DE LA SUSCRIPCIÓN
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Puedes cancelar la renovación de tu Suscripción a través del Sitio. No se te reembolsarán
          las cuotas que ya hayas abonado por el periodo de Suscripción actual y podrás acceder al
          Servicio hasta que finalice dicho periodo.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          REEMBOLSOS
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Las cuotas de Suscripción pagadas no son reembolsables, excepto cuando la ley así lo
          exija. CARPEDIEM podrá estudiar caso por caso determinadas solicitudes de reembolso de las
          Suscripciones y concederlas a su entera discreción.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          DERECHO DE RETRACTO:
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Tienes derecho a retractarte de la compra realizada a través del Sitio o la App, dentro de
          los cinco (5) días hábiles siguientes a la primera compra y podrás ejercer ese derecho
          enviando un correo a carpediemapp2023@gmail.com, siempre y cuando el servicio no se haya
          empezado a prestar; es decir, este derecho no aplicará si ya has iniciado algún test ni
          para las renovaciones del plan adquirido. En caso de que efectivamente sea aplicable el
          derecho de retracto, te informaremos y reembolsaremos el valor total pagado, para lo cual,
          tendremos un plazo de treinta (30) días hábiles siguientes contados desde la fecha en que
          realizaste la solicitud.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          REVERSIÓN DEL PAGO:
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Tendrás derecho a solicitar la reversión del pago realizado a través del Sitio o la App,
          por medio de tu tarjeta de crédito, cuando sea objeto de fraude, corresponda a una
          operación no solicitada, no se ejecute el servicio adquirido o se ejecute un servicio
          diferente al adquirido. Para lo anterior, deberás ejercer el derecho dándonos aviso en
          carpediemapp2023@gmail.com y notificando a tu correspondiente entidad bancaria dentro los
          cinco (5) días hábiles siguientes desde que tuviste conocimiento de tal situación.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Ten en cuenta que a solicitud del emisor deberá ser presentada por el titular del producto
          financiero. Una vez presentada la solicitud de reversión ante nosotros y la notificación
          de la solicitud de reversión ante el emisor del instrumento de pago, contaremos contarán
          con un término de quince (15) días hábiles para hacer efectiva la reversión.
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          INDEMNIDAD - LIMITACIÓN DE RESPONSABILIDAD
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Como usuario, mantendrás libre de responsabilidad a CARPEDIEM por cualquier uso no
          autorizado o fraudulento que se haga del Sitio o de la App a través de tu cuenta de
          usuario, así como cualquier acción u omisión que represente una afectación para CARPEDIEM.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM por su parte, implementará medidas de seguridad apropiadas para el control de
          acceso a la cuenta de usuario, sin embargo, no podrá garantizar resultados que excedan de
          su capacidad de control.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM declara y garantiza que:
          <List>
            <li>
              No tiene relación comercial alguna con universidades o entidades, instituciones
              educativas en virtud de la cual se estructure, defina o analice el contenido de las
              preguntas incluidas en el test.
            </li>
            <li>
              No representa a ninguna universidad o entidades, institución educativa para la
              prestación de los servicios que ofrece.
            </li>
            <li>
              No otorga garantía alguna sobre el desempeño de los usuarios en los exámenes de
              residencia que realicen en las entidades correspondientes luego de haber realizado el
              test ofrecido en su Sitio o en su App.
            </li>
          </List>
        </Footnote>
        <SpaceSmall />
        <Divider />
        <Space />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          DECLARACIONES Y GARANTÍAS DEL USUARIO
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Como usuario, declaras y garantizas que:
          <List>
            <li>Brindarás información real, veraz, cierta y actualizada.</li>
            <li>No crearás más de una (1) cuenta.</li>
            <li>
              Protegerás tu código de acceso y evitarás compartirla con terceros no autorizados para
              acceder a la cuenta.
            </li>
            <li>No infringirás derechos de propiedad intelectual de CARPEDIEM ni de terceros.</li>
            <li>
              No harás un uso no autorizado, antiético o inadecuado del Sitio, de la App, de su
              contenido ni los servicios ofrecidos en ellos
            </li>
            <li>
              Harás un uso del Sitio, la App, sus servicios y beneficios de conformidad con las
              normas aplicables en Colombia.
            </li>
            <li>
              No realizarás actos tendientes a imitar, copiar, modificar o alterar el Sitio, la App
              ni su contenido, lo cual incluye la prohibición de realizar capturas fotográficas o
              videos del contenido suministrado por CARPEDIEM.
            </li>
          </List>
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          COMPORTAMIENTO DEL USUARIO
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para nosotros es fundamental que los usuarios se ajusten a las mejores prácticas, tengan
          comportamientos éticos y, en cualquier caso, nos permitan el adecuado y oportuno
          desarrollo de las relaciones que surjan con ocasión del uso del Sitio y la App, para ello,
          nos reservamos el derecho de rechazar cualquier solicitud de registro o de cancelar una
          cuenta previamente registrada, sin que ello genere algún derecho a indemnización o
          resarcimiento en favor del usuario.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          OPERACIÓN DEL SITIO Y LA APP
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Los servicios no tienen garantía de ausencia de virus ni de otros elementos que puedan
          producir alteraciones en el sistema informático del usuario, por lo tanto, CARPEDIEM no es
          responsable por los daños y perjuicios, cualquiera sea su naturaleza, causados con ocasión
          de dichos eventos.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM no garantiza la disponibilidad y continuidad del funcionamiento ininterrumpido
          del Sitio y la App, por lo tanto, se excluye cualquier responsabilidad por los daños y
          perjuicios sufridos por el Usuario, cualquiera sea su naturaleza, que tengan origen en la
          falta de disponibilidad o de continuidad del funcionamiento del Sitio y la App.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          El Sitio o la App podrán contener referencias y/o vínculos a otros sitios web de propiedad
          de otras personas naturales o jurídicas, lo cual no implica que CARPEDIEM apruebe ni haga
          propios los contenidos ni los servicios ofrecidos por el sitio web enlazado.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En consecuencia, CARPEDIEM no asume responsabilidad alguna por el contenido ni por los
          servicios ofrecidos en dichos sitios web y se excluye cualquier tipo de responsabilidad
          por los daños y perjuicios, cualquiera sea su naturaleza, ocasionados por conceptos tales
          como, pero sin limitarse a:
          <List>
            <li>
              El funcionamiento, disponibilidad, accesibilidad o continuidad de los sitios web
              enlazados.
            </li>
            <li>
              La licitud, calidad, fiabilidad y mantenimiento de los contenidos y servicios de los
              sitios web enlazados.
            </li>
            <li>
              La infracción de los derechos de propiedad intelectual, así como de cualquier otro
              derecho, como consecuencia de los contenidos y de los servicios prestados en los
              sitios web enlazados.
            </li>
          </List>
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM no asume la obligación de controlar al usuario en la utilización correcta,
          prudente y diligente del Sitio, la App, así como del contenido que suministra a través del
          Sitio o la App.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM se reserva el derecho a verificar la identidad del Usuario, así como la
          veracidad y autenticidad de los datos que este proporcione, pero no se encuentra obligado
          a lo mismo. En consecuencia, CARPEDIEM no es responsable por los daños y perjuicios
          causados al usuario y/o a terceros, cualquiera sea su naturaleza, ocasionados por el uso
          indebido del Sitio o la App, la falta de veracidad o autenticidad de la información que el
          usuario suministre a CARPEDIEM ni por la suplantación de la identidad del usuario.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM no será responsable por las consecuencias de los ataques o incidentes contra la
          seguridad del Sitio, la App o contra sus sistemas de información ni por cualquier
          exposición o acceso no autorizado, fraudulento o ilícito al mismo, que puedan afectar la
          confidencialidad, integridad o autenticidad de la información publicada en el Sitio, la
          App o asociada a los Servicios allí ofrecidos.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM no se hace responsable por la información suministrada por los usuarios a través
          de los espacios de participación del Sitio o la App. En todo caso, CARPEDIEM no usará la
          información y/o datos personales contenidos en dichos espacios para fines diferentes a los
          autorizados previamente.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          El usuario se compromete a utilizar el Sitio, la App y los servicios ofrecidos de
          conformidad con la legislación aplicable, las condiciones establecidas en el presente
          documento, el orden público, la moral y las buenas costumbres.
          <List>
            <li>
              Suministrar datos falsos o incorrectos que puedan inducir a CARPEDIEM o a un tercero,
              a error.
            </li>
            <li>
              Enviar o transmitir en los canales tales como, pero sin limitarse a, el Sitio, la App,
              chats y redes sociales contenido obsceno, difamatorio, injuriante, calumniante o
              discriminatorio contra CARPEDIEM y/o cualquier tercero.
            </li>
            <li>
              Introducir o difundir en la red virus informáticos o cualquier otro código de
              naturaleza destructiva.
            </li>
            <li>
              Atentar de cualquier forma contra el Sitio, la App, su plataforma tecnológica o sus
              sistemas de información.
            </li>
            <li>
              Alterar, bloquear o realizar cualquier otro acto que impida mostrar algún contenido o
              acceder a algún servicio del Sitio o la App.
            </li>
            <li>
              Realizar ataques informáticos, interceptación de comunicaciones, uso no autorizado de
              terminales o usurpación de identidad.
            </li>
            <li>
              Infringir patentes comerciales, marcas registradas, secretos comerciales e
              industriales, derechos de publicidad o cualquier otro derecho de personas naturales o
              jurídicas, colectividades, etc.
            </li>
            <li>Impedir o interrumpir el uso del Sitio o la App, por parte de terceros.</li>
            <li>
              Usar programas automáticos, mecanismos o procesos manuales para monitorear, copiar,
              resumir, o extraer información de cualquier otra forma desde el Sitio o la App.
            </li>
            <li>
              Usar las cuentas y claves de terceros usuarios, o cualquier otra información sin el
              consentimiento previo y por escrito de su titular.
            </li>
            <li>
              Transmitir desde este Sitio o la App SPAM, cadenas, correo basura o cualquier otro
              tipo de correo masivo no solicitado.
            </li>
          </List>
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          USO DE COOKIES
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM hace uso de cookies, pixeles y otras tecnologías similares propias o de
          terceros, tanto en el Sitio Web como en el correo electrónico del usuario con la finalidad
          de, entre otras, obtener datos estadísticos de la navegación y registrar las actividades
          del Usuario en el Sitio para mejorar nuestros servicios y su funcionalidad.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Estas herramientas permiten la obtención de, entre otras, la siguiente información:
          <List>
            <li>Tipo de navegador y sistema operativo que utiliza el Usuario.</li>
            <li>Dirección IP.</li>
            <li>Tiempo que permanece en el Sitio Web.</li>
            <li>Número de visitas realizadas al Sitio Web.</li>
          </List>
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Las cookies son identificadores que nuestro servidor le envía a su dispositivo, para
          reconocer el dispositivo que ha sido utilizado durante la visita a nuestro Sitio Web. La
          mayoría de los buscadores están diseñados para aceptar estas cookies automáticamente.{' '}
          <br />
          Al aceptar las cookies estás aceptando su uso y lo anteriormente descrito.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          PROPIEDAD INTELECTUAL
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Para nosotros es fundamental conocer y respetar los derechos de propiedad intelectual, por
          lo anterior, declaramos y garantizamos que usamos y protegemos los activos intangibles
          ajustados a las normas vigentes de propiedad intelectual.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Por su parte, como usuario declaras y garantizas que:
          <List>
            <li>
              No reclamarás propiedad alguna sobre los activos intangibles de propiedad de CARPEDIEM
              puestos a disposición tuya para el acceso a los servicios o beneficios que ofrecemos a
              través del Sitio o la App; en ese sentido, te abstendrás de usarlos en provecho tuyo o
              de terceros con fines distintos a los expresamente autorizados en este documento.
            </li>
            <li>Reconocerás y respetarás los derechos de propiedad intelectual de CARPEDIEM.</li>
            <li>Reconocerás y respetarás los derechos de propiedad intelectual de terceros.</li>
            <li>
              No copiarás, realizarás ingeniería inversa, sustraerás, replicarás y en general no
              harás un uso no autorizado del Sitio, la App y los contenidos puestos a disposición
              por CARPEDIEM.
            </li>
          </List>
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES Y AVISO DE PRIVACIDAD
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Es prioridad para nosotros tomar todas las medidas razonables y necesarias para proteger
          la privacidad de la información de los usuarios, por lo anterior, te pedimos leer en
          detalle nuestra Política de Privacidad y Tratamiento de Datos Personales, disponible para
          acceso al público en https://www.carpediemediapp.com/privacy-policy con la finalidad de
          que conozcas qué información solicitamos y qué tratamiento y protección le brindaremos.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Dando cumplimiento a lo señalado en la Ley 1581 de 2012 y en el Decreto 1377 de 2013, como
          usuario y titular de datos personales autorizas a CARPEDIEM para recolectar, almacenar,
          usar y circular tus datos personales para enviar mensajes con fines comerciales,
          publicitarios y/o de atención, registro de la información en la Base de Datos de
          CARPEDIEM, proveer los servicios ofrecidos en el Sitio, la App y para las demás
          finalidades señaladas en la Política de protección de datos personales de CARPEDIEM y en
          los presentes Términos y condiciones.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Los datos personales que serán recolectados y tratados para las finalidades mencionadas
          anteriormente serán:
          <List>
            <li>Nombres y apellidos.</li>
            <li>Dirección de correo electrónico.</li>
          </List>
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Como el titular de tus datos personales podrás acceder, conocer, actualizar y rectificar
          dichos datos; ser informado sobre el tratamiento dado a los mismos y la autorización con
          que se cuenta para ello; presentar consultas y reclamos; revocar la autorización o
          solicitar la supresión de tus datos, en los casos en que sea procedente de acuerdo con la
          normativa vigente.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          CARPEDIEM pone a tu disposición el siguiente correo: carpediemapp2023@gmail.com para el
          ejercicio de tus derechos como titular de los datos personales.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          DECLARACIONES Y AUTORIZACIONES
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Declaras y garantizas que tus ingresos no provienen de actividades ilícitas y que no
          presentas registros negativos en listados de prevención de lavados de activos nacionales o
          internacionales, ni de financiación del terrorismo, narcotráfico, captación ilegal de
          dinero y en general cualquier actividad ilícita; de igual manera manifiestas que los
          ingresos que se generen durante la vigencia de los presentes Términos y Condiciones no se
          destinarán a ninguna de las actividades anteriormente descritas.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En consecuencia, será justa causa para dar por terminada la relación entre nosotros y
          cancelar tu cuenta, tu inclusión, de tus asociados, o de tus representantes en los
          listados de la OFAC, o de cualquier autoridad local o extranjera, como sospechoso de
          incurrir en cualquiera de dichas actividades.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Expresa e irrevocablemente, autorizas a CARPEDIEM (directamente o a través de un tercero),
          para: (i) que consulte los listados, sistemas de información y bases de datos a que haya
          lugar; ii) efectuar los reportes a las autoridades competentes en materia de lavado de
          activos y financiación al terrorismo, que considere necesarios para garantizar la
          prevención y/o administración del riesgo de lavado de activos y financiación al
          terrorismo; (iii) consultar, solicitar, suministrar, reportar, procesar y divulgar toda la
          información que se refiere a mi comportamiento crediticio, financiero y comercial a la
          Central de Información Financiera –CIFIN- que administra la Asociación Bancaria y demás
          Entidades Financieras de Colombia o a quienes representen sus derechos.
        </Footnote>
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Asimismo, te obligas a informar inmediatamente a CARPEDIEM una vez tengas conocimiento del
          hecho, cuando tus asociados, administradores, clientes, proveedores, empleados, y en
          general personas con quien tienes relación tu y tus recursos, se encuentren relacionados o
          provengan de actividades ilícitas, particularmente las mencionadas anteriormente.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          LEY Y JURISDICCIÓN APLICABLE
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Los presentes Términos y Condiciones se regirán por las normas aplicables en Colombia y
          cualquier decisión será sometida a los Tribunales competentes del territorio colombiano.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          TERMINACIÓN
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Si incumples los Términos y Condiciones total o parcialmente o crearas algún riesgo para
          la operación del Sitio o la App de CARPEDIEM, su reputación, el personal directo o
          indirecto o la prestación de los servicios que ofrece, podremos suspender o cancelar tu
          registro, sin que ello te de derecho alguno a percibir indemnización o resarcimiento por
          la medida tomada por nosotros.
          <br /> En ese caso, te informaremos sobre la medida que hemos tomado y las razones que la
          han motivado.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          CANCELACIÓN DE LA CUENTA
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Si deseas cancelar tu cuenta, simplemente dejas de utilizar el Servicio. <br />
          Cómo eliminar su cuenta: <br />
          Vaya a Mi Cuenta - Haga clic en Eliminar mi cuenta
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          RESOLUCIÓN DE CONFLICTOS
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          En caso de presentarse diferencias entre las partes, relacionadas con los presentes
          Términos y Condiciones, su ejecución o terminación, que no puedan ser resueltas
          directamente entre nosotros, se intentará resolver inicialmente mediante la justicia
          ordinaria.
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          VIGENCIA{' '}
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          Los presente términos y condiciones rigen desde su publicación
        </Footnote>
        <Spacer size="78" />
        <H6 color="darkAquaColor" fontWeight={fontWeightType.Bold}>
          IDIOMA
        </H6>
        <SpaceSmall />
        <Footnote color="darkAquaColor" fontWeight={fontWeightType.Medium}>
          El Sitio, sus contenidos, la prestación de los servicios y todos los Términos y
          Condiciones asociadas a la misma serán en español.
        </Footnote>
        <Spacer size="78" />
        <Spacer size="78" />
      </Copy>
      <Footer />
    </Base>
  );
};

export default TermsAndConditions;

export const Head: HeadFC = () => <Seo title="Carpediem MediAPP - Terms And Conditions" />;
